@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .active {
      @apply text-primary
  }
}

* {
  @apply m-0 p-0;
}